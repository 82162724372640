import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(): boolean {
    return true;
    if (!this.auth.isAuthenticated()) {
        this.router.navigateByUrl('/login');
        return false;
    }
    return true;
  }
  canActivateChild(): boolean {
    return true;
    if (!this.auth.isAuthenticated()) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
  canLoad(): boolean {
    return true;
    if (!this.auth.isAuthenticated()) {
       this.router.navigateByUrl('/login');
        return false;
    }
    return true;
  }
}
