import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import {AppLayoutComponent} from "./pages/app-layout/app-layout.component";

const routes: Routes = [
    {
        path: '',
        loadChildren: './pages/landing/landing.module#LandingModule',
    },
    {
        path: 'app',
        loadChildren: './pages/app-layout/app-layout.module#AppLayoutModule',
        component: AppLayoutComponent,
        canLoad: [AuthGuardService]
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
