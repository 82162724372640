import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LoginResponse, RegisterResponse } from '../models/auth-response';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ResponseToken } from '../models/response-token';
import { UserInfoService } from './userInfo.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  basePath = environment.authUrl;

  isAuth = false;
  isAdmin = true;

  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private userInfo: UserInfoService,
    public jwtHelper: JwtHelperService
  ) {

  }

  public async initUser() {
    const res: LoginResponse = await this.api.getAsync(this.basePath + '/api/auth/user');
    if (!res.isError) {
      this.setUser(res);
    }

  }

  public async login(data): Promise<LoginResponse> {
    return await this.api.postAsync(this.basePath + '/api/auth/login', data);
  }

  public async forgotPassword(data): Promise<RegisterResponse> {
    return await this.api.postAsync(this.basePath + '/api/auth/forgot', data);
  }

  public async register(data): Promise<RegisterResponse> {
    return await this.api.postAsync(this.basePath + '/api/auth/register', data);
  }

  public setUser(resp: LoginResponse) {
    console.log('dkfklfkdslkf');
    this.isAuth = true;
    this.isAdmin = resp.isAdmin;
    this.userInfo.setUsername(resp.email);
    this.userInfo.setUserId(resp.userId);
    this.userInfo.setIsAdmin(this.isAdmin);
  }

  public async logout() {
    const res = await this.api.getAsync(this.basePath + '/api/auth/logout');
    this.router.navigate(['/']);
    this.isAuth = false;
  }

  public isAuthenticated(): boolean {
    return this.isAuth;
  }



}
