import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private api: ApiService) { }

  lang = new BehaviorSubject<string>('en');

  async getLanguage() {
    return await this.api.getAsync('/api/language');
  }

  async setLanguage(lang: string) {
    await this.api.postAsync(`/api/language/${lang}`);
    this.lang.next(lang);
  }
}


