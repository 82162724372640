import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable()
export class UserInfoService {
    public title = new BehaviorSubject('ТехПлан');
    public username = new BehaviorSubject(localStorage.getItem('name'));
    public userId = new BehaviorSubject(0);
    public isAdmin = new BehaviorSubject(false);

    setPageTitle(title: string) {
        this.title.next(title);
    }

    setUsername(username: string) {
        this.username.next(username);
    }

    setUserId(userId: number) {
        this.userId.next(userId);
    }

     setIsAdmin(isAdmin: boolean) {
        this.isAdmin.next(isAdmin);
    }
}
