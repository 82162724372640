import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { LanguageService } from './services/language.service';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // Parameter to display Sidenav: false = hide / true = show
  visibleSidenav = true;
  title = 'front';

  constructor(
    private apiService: ApiService,
    private router: Router,
    public authService: AuthService,
  ) {
  }

 async ngOnInit(): Promise<void> {
      await this.authService.initUser();
  }


}
