import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
// import {MaterialModule} from './material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import {JwtModule} from '@auth0/angular-jwt';
import {from} from 'rxjs';
import {UserInfoService} from './services/userInfo.service';
import {AppLayoutComponent} from "./pages/app-layout/app-layout.component";
import {HeaderAppComponent} from "./pages/app-layout/header-app/header-app.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    HeaderAppComponent,

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        // MaterialModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                whitelistedDomains: ['localhost'],
                blacklistedRoutes: ['localhost/auth/login']
            }
        }),
    ],
    providers: [
        AuthService,
        ApiService,
        AuthGuardService,
        UserInfoService,
    ],
    entryComponents: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function jwtTokenGetter() {
    return localStorage.getItem('access_token');
}
