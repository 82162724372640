import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserInfoService } from 'src/app/services/userInfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-header-app',
    templateUrl: './header-app.component.html',
    styleUrls: ['./header-app.component.scss']
})
export class HeaderAppComponent implements OnInit {

    @Output() public sidenavToggle = new EventEmitter();
    title = '';
    username = '';
    isAdmin = false;

    constructor(
        private userInfoService: UserInfoService,
        public authService: AuthService,
        private router: Router,
        public translate: TranslateService,
        private langService: LanguageService
    ) {

        userInfoService.title.subscribe(value => this.title = value);
        userInfoService.username.subscribe(value => this.username = value);
        userInfoService.isAdmin.subscribe(value => this.isAdmin = value);
    }


    ngOnInit() {
    }

    getLinkClass(routePath) {
        if (routePath === this.router.url) {
            return 'active';
        }
        return '';
    }
    async logout() {
        await this.authService.logout();
    }


}
