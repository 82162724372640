import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from './../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    }),
    responseType: '',
    params: new HttpParams({}),
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {

    }

    getHttpOptions(headers?: any, responseType?: string, params?: any): any {
        const hopt = Object.assign({}, httpOptions);
        if (headers) {
            hopt.headers = Object.assign(headers, httpOptions.headers);
        }
        if (responseType) {
            hopt.responseType = responseType;
        }
        if (params) {
            hopt.params = new HttpParams({fromObject: params});
        }
        return hopt;
    }

    get(url: string, params?: any, headers?: any, responseType?: string): Observable<any> {
        const hopt = this.getHttpOptions(headers, responseType);
        if (params) {
            hopt.params = new HttpParams({fromObject: params});
        }
        // console.log('GET ' + url, JSON.stringify(hopt));
        return this.http.get(`${environment.apiUrl}${url}`, hopt)
            .pipe(catchError(err => {
                throw new Error('unexpected error');
            }));
    }

    async getAsync(url: string, params?: any, headers?: any, responseType?: string): Promise<any> {
        return this.get(url, params, headers, responseType).toPromise();
    }

    post(url: string, body = null, headers?: any, responseType?: string): Observable<any> {
        const hopt = this.getHttpOptions(headers, responseType);
        return this.http.post(`${environment.apiUrl}${url}`, body, hopt)
            .pipe(
                catchError(err => {
                    throw new Error('unexpected error');
                }));
    }

    async postAsync(url: string, body = null, headers?: any, responseType?: string): Promise<any> {
        return this.post(url, body, headers, responseType).toPromise();
    }

    put(url: string, body = null): Observable<any> {
        const hopt = this.getHttpOptions();
        return this.http.put(`${environment.apiUrl}${url}`, body, hopt)
            .pipe(
                catchError(err => {
                    throw new Error('unexpected error');
                }));
    }

    async putAsync(url: string, body = null, headers?: any, responseType?: string): Promise<any> {
        return this.put(url, body).toPromise();
    }

    delete(url: string, params?: any): Observable<any> {
        const hopt = this.getHttpOptions(null, null, params);
        // console.log('GET ' + url, JSON.stringify(hopt));
        return this.http.delete(`${environment.apiUrl}${url}`, hopt)
            .pipe(catchError(err => {
                throw new Error('unexpected error');
            }));
    }

    async deleteAsync(url: string, params?: any): Promise<any> {
        return this.delete(url, params).toPromise();
    }

    postUpload(url: string, formData: FormData): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                // 'Content-Type': 'multipart/form-data', // comment this out
            })
        };
        return this.http.post(`${environment.apiUrl}${url}`, formData, options)
            .pipe(
                catchError(err => {
                    throw new Error('unexpected error');
                }));
    }

    async postUploadAsync(url: string, formData: FormData): Promise<any> {
        return this.postUpload(url, formData).toPromise();
    }

}




